export function RegularText(props) {
    const baseClasses = [];

    switch(props.size){
        case 'big':
            baseClasses.push('text-2xl');
            break;
        case 'medium':
            baseClasses.push('text-lg');
            break;
        default:
            baseClasses.push('text-base');
            break;
    }

    if(props.allCaps === true){
        baseClasses.push('uppercase');
    }

    switch(props.color){
        case 'white':
            baseClasses.push('text-white');
            break;
        case 'purple':
            baseClasses.push('text-indigo-700');
            break;
        default:
            baseClasses.push('text-black');
            break;
    }

    return (
        <p className={baseClasses.join(" ")}>
            {props.content}
        </p>
    );
}
