export function Link(props) {
    let baseClasses = [
        "uppercase",
        "font-semibold",
        "text-cinza-kasa-300",
        "text-lg",
        "border-b-4",
    ];

    if(props.selected !== true){
        baseClasses = baseClasses.concat([
            "border-b-transparent",
            "hover:border-b-4",
            "hover:border-azul-kasa-200",
        ]);
    }

    if(props.selected === true){
        const toAdd = [
            "border-azul-kasa-200"
        ]

        baseClasses = baseClasses.concat(toAdd);
    }

    return (
        <div className={baseClasses.join(" ")} >
            {props.content}
        </div>
    );
}
