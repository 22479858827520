import { useLocation, Link as RouterLink  } from "react-router-dom";
import hamburgerIcon from "../../assets/hamburguer-icon.png";

export function BotaoHamburguer(props) {

    return (
        <div onClick={props.click} className={`hamburger-wrapper-${props.isOpen ? 'open' : 'closed'} esconde-se-grande z-50`}>
            <img className="p-6" src={hamburgerIcon} />
        </div>
    );
}

export function HamburgerOverlay(props) {
    const show = props.isOpen;

    let params = useLocation();
    const { pathname } = params;

    let currentRoute = pathname.substring(1);

    const createItem = (value, active) => {
        return (
            <h1 className={`py-6 uppercase font-semibold text-cinza-kasa-300 text-2xl ${currentRoute === active ? 'border-b-4 border-azul-kasa-200' : 'hover:border-b-4 hover:border-azul-kasa-200'}`}>{value}</h1>
        );
    }

    const buttonClasses = [
        "uppercase",
        "font-bold",
        "p-4",
        "text-2xl",
        "border",
        "text-white",
        "my-8"
    ];

    if (show) {
        return (
            <div className="esconde-se-grande fixed block top-0 left-0 z-30 h-full w-full bg-azul-kasa-200 ">
                <div className="p-8 flex flex-col justify-center content-center">
                    <RouterLink onClick={props.click} to="/">
                        {createItem('Home', '')}
                    </RouterLink>
                    {createItem('Sobre nós', 'fakeItem')}
                    <RouterLink onClick={props.click} to="/portas">
                        {createItem('Portas', 'portas')}
                    </RouterLink>
                    <RouterLink onClick={props.click} to="/janelas">
                        {createItem('Janelas', 'janelas')}
                    </RouterLink>
                    <div className="py-8">
                        <a className={buttonClasses.join(" ")} href="#">Orçamento</a>
                    </div>
                </div>
            </div>
        );

    } else {
        return <></>
    }
}