export function Subtitle(props) {
    let baseClasses = ['font-montserrat'];

    switch(props.size){
        case 'huge':
            baseClasses = baseClasses.concat(['font-bold', 'lg:text-7xl sm:text-4xl'])
            break;
        case 'semihuge':
            baseClasses = baseClasses.concat(['font-bold italic', 'lg:text-3xl sm:text-xl'])
            break;
        case 'big-bold':
            baseClasses = baseClasses.concat(['font-bold', 'lg:text-2xl sm:text-lg'])
            break;
        case 'big':
            baseClasses = baseClasses.concat(['font-normal', 'lg:text-2xl sm:text-lg'])
            break;
        case 'medium':
            baseClasses = baseClasses.concat(['font-normal', 'lg:text-lg'])
            break;
        case 'medium-bold':
            baseClasses = baseClasses.concat(['font-bold', 'lg:text-lg'])
            break;
        case 'tooltip-bold':
            baseClasses = baseClasses.concat(['font-semibold', 'sm:text-xs', 'lg:text-base'])
            break;
        default:
            break;
    }

    if(props.allCaps === true){
        baseClasses.push('uppercase');
    }

    switch(props.color){
        case 'gray':
            baseClasses.push('text-cinza-kasa-300');
            break;
        case 'white':
            baseClasses.push('text-white');
            break;
        case 'purple':
            baseClasses.push('text-indigo-700');
            break;
        default:
            break;
    }

    return (
        <h6 className={baseClasses.join(" ")}>
            {props.content}
        </h6>
    );
}
