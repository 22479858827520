import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Subtitle } from "./components/atoms/Subtitle";
import { Button } from "./components/atoms/Button";
import { Link as RouterLink } from "react-router-dom"
import { Title } from "./components/atoms/Title";
import { Link } from "./components/atoms/Link";
import { RegularText } from "./components/atoms/RegularText";
import { SemiCircleHeader } from "./components/molecules/SemiCircleHeader";
import { TopMostHeader } from "../src/components/molecules/TopMostHeader";
import { Signature } from "../src/components/molecules/Signature";
import hamburgerIcon from "./assets/hamburguer-icon.png";
import engenheiro from "./assets/engenheiro.png";
import {HamburgerOverlay, BotaoHamburguer} from "../src/components/atoms/HamburgerHelpers";

function FirstImageHeader() {
    return (
        <div className="bg-black w-full h-64 lg:h-128 background-header-1 ">
            <div className="p-8 float-right mt-96">
                <Subtitle size="big-bold" color="white" content="A melhor escolha para a sua obra!" />
            </div>
        </div>
    );
}

function SobreNos() {
    return (
        <div className="w-full pt-16">
            <img className="float-left relative z-20" src={engenheiro} />
            <div className="z-10 ml-48 mt-72 bg-azul-kasa-800 w-5/6 h-96">
                <div className="py-36 px-4">
                    <Subtitle
                        className="mt-24"
                        size="semihuge"
                        color="white"
                        content="Aqui na KASA contamos com janelas e portas de alumínio feitos com matéria-prima de qualidade para sua obra."
                    />

                </div>
            </div>
        </div>
    );
}

function NossosServicos() {
    return (
        <div className="w-full text-center py-8 lg:p-8">
            <div className="p-8">
                <Title
                    hasBorder={true}
                    size="big"
                    color="purple"
                    content="Nossos serviços"
                />
            </div>
            <div className="py-16 text-left">
                <SemiCircleHeader
                    headerImageClass="background-header-portas"
                    content="Portas"
                />
                <div className="p-8 w-11/12">
                    <RegularText size="big" content="Interdum et malesuada fames ac ante ipsum primis in faucibus. Nunc erat neque, vestibulum ac neque in, fringilla fringilla nulla. Vivamus semper justo vitae elit ullamcorper, non rhoncus metus semper. Aenean rutrum maximus aliquet. Integer mattis congue dolor, sit amet congue purus consequat eget. Curabitur vel nibh tristique, finibus nisl id, aliquam ipsum. Aenean eleifend porttitor libero in mattis. Phasellus convallis, nulla nec varius aliquet, velit tellus consectetur nisl, tristique mattis enim ex eu diam. Praesent laoreet mi ac eros tempus pulvinar." />
                </div>
            </div>
            <div className="py-16 text-left">
                <SemiCircleHeader
                    headerImageClass="background-header-janelas"
                    content="Janelas"
                />
                <div className="p-8 w-11/12">
                    <RegularText size="medium" content="Cras vitae nisi consequat tellus rhoncus interdum ut at mi. Nulla facilisi. Phasellus fringilla a tellus ut faucibus. Pellentesque mattis, diam eu aliquam sagittis, erat metus aliquam felis, sit amet sagittis dolor purus quis velit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vestibulum tempor maximus diam vel dictum. Nam nisi felis, molestie sit amet ligula quis, accumsan dapibus nunc. Suspendisse sed ex ut felis tempus scelerisque id a sem. In hac habitasse platea dictumst. Donec accumsan risus et urna bibendum, ut auctor neque malesuada. Nulla mattis consequat tellus quis volutpat." />
                </div>
            </div>
        </div>
    );

}

function Content() {
    return (
        <div className="bg-cinza-kasa-100 w-full">
            <SobreNos />
        </div>
    );
}

function App() {
    const [open, setOpen] = useState(false)

    const toggleOpen = () => {
        setOpen(!open)
    }

    return (
        <div className="font-montserrat bg-cinza-kasa-100 mx-auto" style={{maxWidth: '1200px'}}>
            <TopMostHeader blueVariant={true} />
            <FirstImageHeader />
            <Content />
            <Signature />
            <BotaoHamburguer click={toggleOpen} isOpen={open} />
            <HamburgerOverlay click={toggleOpen} isOpen={open} />
        </div>
    );
}

export default App;
