import { Link as RouterLink } from "react-router-dom"
import { useLocation } from "react-router-dom";
import instagramLogo from "../../assets/instagram-logo-24px.png";
import hamburgerIcon from "../../assets/hamburguer-icon.png";
import instagramOutline from "../../assets/instagram-outline.svg";
import instagramOutlineWhite from "../../assets/instagram-outline-white.svg";
import kasaLogo from "../../assets/logo-kasa.svg";
import { Subtitle } from "../atoms/Subtitle";
import { Link } from "../atoms/Link";
import { Button } from "../atoms/Button";

/*
          <Subtitle
            size="tooltip-bold"
            color="gray"
            content="@kasaesquadriasdealuminio"
          />
 * */
function ContactAndSocialMedia(props) {
    if (props.blue === true){
        return (
            <div
              className="bg-azul-kasa-800 text-white py-4 px-8 flex justify-between"
              style={{flexWrap: 'wrap'}}
            >
              <div className="flex justify-center items-center">
                <a
                  className="text-white font-montserrat font-semibold text-sm lg:text-lg flex items-center link-decoration"
                  href="https://www.instagram.com/kasaesquadriasdealuminio/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="instagram logo" src={instagramOutlineWhite} className="
                    h-4
                    w-4
                    mr-0.5
                    lg:h-5
                    lg:w-5
                    lg:mr-1
                    stroke-white
                  " />
                  @kasaesquadriasdealuminio
                </a>
              </div>
              <div>
                <div className="flex gap-5 div-links-top-header">
                  <a
                    className="text-white font-montserrat font-semibold text-sm lg:text-lg link-decoration"
                    href="https://wa.me/5511947268284"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    (11) 94726-8284
                  </a>
                  <h6 className="text-white font-montserrat font-semibold text-sm lg:text-lg"> | </h6>
                  <a
                    className="text-white font-montserrat font-semibold text-sm lg:text-lg link-decoration"
                    href="mailto:vendas@kasaesquadrias.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    vendas@kasaesquadrias.com
                  </a>
                </div>
              </div>
            </div>
        );

    }

    return (
        <div
          className="bg-cinza-kasa-100 py-4 px-8 flex justify-between"
          style={{flexWrap: 'wrap'}}
        >
          <div className="flex justify-center items-center">
            <a
              className="text-cinza-kasa-300 font-montserrat font-semibold text-sm lg:text-lg flex items-center link-decoration"
              href="https://www.instagram.com/kasaesquadriasdealuminio/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="instagram logo" src={instagramOutline} className="
                stroke-white
                h-4
                w-4
                mr-0.5
                lg:h-5
                lg:w-5
                lg:mr-1
              " />
              @kasaesquadriasdealuminio
            </a>
          </div>
          <div>
            <div className="flex gap-5">
              <a
                className="text-cinza-kasa-300 font-montserrat font-semibold text-sm lg:text-lg link-decoration"
                href="https://wa.me/5511947268284"
                target="_blank"
                rel="noopener noreferrer"
              >
                (11) 94726-8284
              </a>
              <h6 className="text-cinza-kasa-300 font-montserrat font-semibold text-sm lg:text-lg"> | </h6>
              <a
                className="text-cinza-kasa-300 font-montserrat font-semibold text-sm lg:text-lg link-decoration"
                href="mailto:vendas@kasaesquadrias.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                vendas@kasaesquadrias.com
              </a>
            </div>
          </div>
        </div>
    );
}

function LogoRoutesAndButtons() {
    let params = useLocation();
    const { pathname } = params;

    let currentRoute = pathname.substring(1);

    return (
        <div 
          className="bg-white w-full h-36 flex justify-center items-center"
          style={{overflow: 'hidden'}}
        >
          <div className="esconde-se-pequeno bg-white w-full h-36 flex justify-center px-12">
              <div className="w-full flex justify-items-end items-center div-links">
                  <img className="h-36 mr-auto" src={kasaLogo} />
                  <RouterLink className="mx-2.5" to="/">
                      <Link content="Home" selected={currentRoute === ''} />
                  </RouterLink>
                  <RouterLink className="mx-2.5" to="/portas">
                      <Link content="Portas" selected={currentRoute === 'portas'} />
                  </RouterLink>
                  <RouterLink className="ml-2.5 mr-5" to="/janelas">
                      <Link content="Janelas" selected={currentRoute === 'janelas'} />
                  </RouterLink>
                  <Button href="#" content="Orçamento" />
              </div>
          </div>
          <div className="esconde-se-grande w-full px-8 flex">
              <img className="mx-auto" src={kasaLogo} />
          </div>
        </div>
    );
}

export function TopMostHeader(props) {
    const blueVariant = props.blueVariant;
    return (
        <>
            <ContactAndSocialMedia blue={blueVariant} />
            <LogoRoutesAndButtons />
        </>
    )
}
