import emailOutline from "../../assets/email-logo.svg";
import telefoneOutline from "../../assets/telefone-outline.svg";
import instagramOutline from "../../assets/instagram-outline.svg";
import { Subtitle } from "../atoms/Subtitle";

export function Signature() {
  return (
    <div className="bg-white w-full h-72 border-y-8 mb-0 border-azul-kasa-200 flex items-center">
      <div className="grid grid-cols-1 grid-rows-3 w-full text-center">
        <div className="flex items-center justify-center">
          <img
            src={telefoneOutline}
            className="h-8 w-8 pr-1 stroke-cinza-kasa-200"
          />
          <a
            className="font-montserrat font-bold lg:text-lg text-cinza-kasa-300 link-decoration"
            href="https://wa.me/5511947268284"
            target="_blank"
            rel="noopener noreferrer"
          >
            (11) 94726-8284
          </a>
        </div>
        <div className="flex items-center justify-center">
          <img src={emailOutline} className="h-8 w-8 pr-1 stroke-cinza-kasa-200" />
          <a
            className="font-montserrat font-bold lg:text-lg text-cinza-kasa-300 link-decoration"
            href="mailto:vendas@kasaesquadrias.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            vendas@kasaesquadrias.com
          </a>
        </div>
        <div className="flex items-center justify-center">
          <img
            src={instagramOutline}
            className="h-8 w-8 pr-1 stroke-cinza-kasa-200"
          />
          <a
            className="font-montserrat font-bold lg:text-lg text-cinza-kasa-300 link-decoration"
            href="https://www.instagram.com/kasaesquadriasdealuminio/"
            target="_blank"
            rel="noopener noreferrer"
          >
            @kasaesquadriasdealuminio
          </a>
        </div>
      </div>
    </div>
  );
}