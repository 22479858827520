import { useState } from 'react';
import { RegularText } from '../components/atoms/RegularText';
import { Subtitle } from '../components/atoms/Subtitle';
import { TopMostHeader } from '../components/molecules/TopMostHeader';
import { Signature } from '../components/molecules/Signature';
import {HamburgerOverlay, BotaoHamburguer} from "../components/atoms/HamburgerHelpers";

function FirstImageHeader() {
  return (
    <div className="bg-black w-full h-128 background-page-header-janelas flex items-end">
      <div className="p-8 border-l-8 border-white max-w-xl max-h-48 ml-8 mb-8">
        <Subtitle size="huge" color="white" content="Janelas" />
      </div>
    </div>
  );
}

function PageDescription() {
    return (
        <div className="w-full px-8 py-16 flex flex-wrap space-y-4 justify-left items-center">
            <RegularText size="big" content="Aqui na KASA buscamos sempre realizar o melhor serviço para você, solucionando seus problemas com as janelas. Trabalhamos com alumínio para garantir um serviço excelente, de qualidade e duradouro." />
            <RegularText size="big" content="Confira alguns dos nossos serviços realizados:" />
        </div>
    )
}

function Squares() {
    return (
        <>
            
            <div className="w-full flex px-8 justify-around mb-8">
                <div className="w-80 h-auto aspect-square background-tile-demo-janelas-1" />
                <div className="w-80 h-auto aspect-square background-tile-demo-janelas-2" />
                <div className="w-80 h-auto aspect-square background-tile-demo-janelas-3" />
            </div>
            <div className="w-full flex px-8 justify-around mb-8">
                <div className="w-80 h-auto aspect-square background-tile-demo-janelas-4" />
                <div className="w-80 h-auto aspect-square background-tile-demo-janelas-5" />
                <div className="w-80 h-auto aspect-square background-tile-demo-janelas-6" />
            </div>
        </>
    )
}

export function Janelas() {
    const [open, setOpen] = useState(false)

    const toggleOpen = () => {
        setOpen(!open)
    }

    return (
        <div className="font-montserrat mx-auto" style={{maxWidth: '1200px'}}>
            <TopMostHeader />
            <FirstImageHeader />
            <PageDescription />
            <Squares />
            <Signature />
            <BotaoHamburguer click={toggleOpen} isOpen={open} />
            <HamburgerOverlay click={toggleOpen} isOpen={open} />
        </div>
    );
}