export function Button(props) {
    let baseClasses = [
        "uppercase",
        "font-bold",
        "p-4",
        "text-lg",
        "border",
    ];

    if(props.selected === true){
        baseClasses = baseClasses.concat([
            "border-neutral-600",
            "text-neutral-600",
            "hover:text-purple-500",
            "hover:border-purple-500",
        ]);
    }else {
        baseClasses = baseClasses.concat([
            "text-azul-kasa-200",
            "border-azul-kasa-200",
            "hover:text-azul-kasa-100",
            "hover:border-azul-kasa-100",
        ]);
    }

    return (
        <a href={props.href} className={baseClasses.join(" ")}>
            {props.content}
        </a>
    );
}
