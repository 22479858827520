import { useState } from "react";
import { RegularText } from '../components/atoms/RegularText';
import { Subtitle } from '../components/atoms/Subtitle';
import { TopMostHeader } from '../components/molecules/TopMostHeader';
import { Signature } from '../components/molecules/Signature';
import {HamburgerOverlay, BotaoHamburguer} from "../components/atoms/HamburgerHelpers";

function FirstImageHeader() {
  return (
    <div className="bg-black w-full h-128 background-page-header-portas flex items-end">
      <div className="p-8 border-l-8 border-white max-w-xl max-h-48 ml-8 mb-8">
        <Subtitle size="huge" color="white" content="Portas" />
      </div>
    </div>
  );
}

function PageDescription() {
    return (
        <div className="w-full px-8 py-16 flex flex-wrap space-y-4 justify-left items-center">
            <RegularText size="big" content="As portas de alumínio são uma ótima opção para quem busca preço bom e alta durabilidade, sem perder a sofisticação! Além do material ser leve, não prejudicando a estrutura da casa." />
            <RegularText size="big" content="Confira alguns dos nossos serviços:" />
        </div>
    )
}

function Squares() {    
    return (
        <>
            
            <div className="w-full flex px-8 justify-around mb-8">
                <div className="w-80 h-auto aspect-square background-tile-demo-portas-1" />
                <div className="w-80 h-auto aspect-square background-tile-demo-portas-2" />
                <div className="w-80 h-auto aspect-square background-tile-demo-portas-3" />
            </div>
            <div className="w-full flex px-8 justify-around mb-8">
                <div className="w-80 h-auto aspect-square background-tile-demo-portas-4" />
                <div className="w-80 h-auto aspect-square background-tile-demo-portas-5" />
                <div className="w-80 h-auto aspect-square background-tile-demo-portas-6" />
            </div>
            <div className="w-full flex px-8 justify-around mb-8">
                <div className="w-80 h-auto aspect-square background-tile-demo-portas-7" />
                <div className="w-80 h-auto aspect-square background-tile-demo-portas-8" />
                <div className="w-80 h-auto aspect-square " />
            </div>
        </>
    )
}

export function Portas() {
    const [open, setOpen] = useState(false)

    const toggleOpen = () => {
        setOpen(!open)
    }

    return (
        <div className="font-montserrat mx-auto" style={{maxWidth: '1200px'}}>
            <TopMostHeader />
            <FirstImageHeader />
            <PageDescription />
            <Squares />
            <Signature />
            <BotaoHamburguer click={toggleOpen} isOpen={open} />
            <HamburgerOverlay click={toggleOpen} isOpen={open} />
        </div>
    );
}