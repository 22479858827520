import React from "react";
import ReactDOM from "react-dom";
import "./compiled.css";
import App from "./App";
import { Janelas } from "./pages/Janelas";
import { Portas } from "./pages/Portas";
import reportWebVitals from "./reportWebVitals";
import { render } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";


ReactDOM.render(
  render(
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/portas" element={<Portas />} />
          <Route path="/janelas" element={<Janelas />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
  )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
